
.requirement-container {
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start; 
    justify-content: flex-start;
    gap: 10px;
    
  }
.arrow{
  width: 10px;
  height: 10px;
  margin-top: 10px;
}

.requirement-content{
    font-size: 16px;
    font-weight: 400;
    font-family: 'Lato' sans-serif;
    color: #000000;
    opacity: 0.8;
    word-wrap: break-word;
    width: 100%;
    text-align: start;
    display: inline-block;
    vertical-align: middle;
}

  /* for tablets */
  @media screen and (max-width: 992px) {
    
  
    .requirement-content {
      width: 100%;
      font-size: 14px;
      line-height: 20px;
    }
    .right-arrow {
      width: 14px; 
      height: 14px;
    }
  } 
  