.cardDescription{
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    word-wrap: break-word;
    white-space: nowrap; 
    /* overflow-wrap: break-word; */
    overflow: hidden;
    text-overflow: ellipsis;
    
  /* max-height: 40px;  */
}