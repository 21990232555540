.brandContainer{
    width: 100%;
    background-color: rgb(239, 239, 239);
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}
.brandSubContainer{
    width: 75%;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    /* border: 1px solid blue; */
    
}
.brandDescriptionContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
   
    /* border: 1px solid red; */
}

.circularCardContainer{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: flex-start;
    align-items: center;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .brandSubContainer{
        width: 90%;
    }
}

@media (min-width:768px) and (max-width: 992px) {
    .brandSubContainer{
        width: 90%;
    }
}

@media (max-width: 768px) {
    .brandSubContainer{
        width: 90%;
    }
    .brandDescriptionContainer{
        /* padding: 20px 30px; */
    }

    .circularCardContainer{
        gap: 10px;
        justify-content: center;
        align-items: center;
    }
}