
.uxDesignContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}
.uxDesignSubContainer{
    width: 75%;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    /* border: 1px solid blue; */
}


.uxDescriptionContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.customCardContainer{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items:  flex-start;
    justify-content: flex-start;
}
.cardsContainer{
    width: 100%;
    display: flex;
    flex-wrap: wrap; /* Changed to wrap here */
    justify-content: flex-start;
    gap: 20px;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .uxDesignSubContainer{
        width: 90%;
    }
    .cardsContainer{
        justify-content: center;
    }
}

@media (min-width:768px) and (max-width: 992px) {
    .uxDesignSubContainer{
        width: 90%;
    }
    .cardsContainer{
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .uxDesignSubContainer{
        width: 90%;
        gap: 0px;
    }
    .customCardContainer{
        overflow-x: auto;
         white-space: nowrap;
         display: inline-flex;
         flex-wrap: nowrap;
         width: 100%;
         /* flex-direction: row; */
         justify-content: flex-start;
         /* width: 100%; */
    }
    .cardsContainer{
        display: inline-flex;        
         flex: 0 0 auto; /* Allow cards to shrink when necessary */
         margin-right: 10px; 
         padding: 0px 10px;
        width: auto;
        justify-content: flex-start;
        flex-wrap: nowrap;
    }
}