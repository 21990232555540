.mainContainer{
    margin-top: 5rem;
    /* height: calc(100vh-60px); */
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    /* border: 1px solid red; */

}

.introContainer{
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    justify-content: flex-end;
    align-items: flex-start;
    
}
.innerNav{
    display: flex;
    width: 100%;
    /* padding: 0px 20px; */
    height: 40vh;
    /* border: 1px solid blue; */
    align-items: flex-start;
    justify-content: flex-end;
}

.innerItem{
    display: flex;
    flex-direction: column;
}

.leftIntro {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    /* border: 1px solid pink; */
  }

  .rightIntro {
    width: 774px;
    /* height:657px; */
    padding: 0; 
    
    
    /* margin-top: 20px;  */
  }
  .rightIntro > svg {
    width: 100%; 
    height: auto; 
  }

.title{
    font-size: 60px;
    font-weight: 600;
    line-height: 50px;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
}

.heading{
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
}

.subHeading{
    font-size: 50px;
    font-weight: 700;
    line-height: 16px;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
}

.description{
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    text-align: start;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
}

.contentContainer{
    background-color: rgb(239, 239, 239);
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */

}

.descriptionContainer{
    /* background-color: rgb(239, 239, 239); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;
    width: 100%;
    
    /* border: 1px solid red; */
}
.descriptionSubContainer{
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
padding-bottom: 5rem;

    /* border: 1px solid red; */
}

.capabilitiesContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;
    width: 100%;
    
    /* border: 1px solid red; */
}

.capabilitiesSubContainer{
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: center;
    /* border: 1px solid red; */
}
/* .scrollableContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    border: 1px solid red;
} */
.customCardContainer{
    width: 75%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    /* border: 1px solid green; */
}
.cardsContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap; /* Changed to wrap here */
    gap: 15px; /* Added gap for spacing between cards */
    /* border: 1px solid pink; */
  }

  .cardWraper {
    /* width: 100%; 
    max-width: 350px; 
    flex: 1;  */
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* padding: 5px; */
    border: 1px solid purple
  }

  .customCard {
    max-width: 330px;
    width: 100%; 
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(239, 239, 239);
    padding-top: 10px; 
    padding-bottom: 30px;
    background-color: #fff;
    border-radius: 25px;
  }

  .capabilitiesCardTitle{
    font-size: 25px;
    padding: 10px;
    font-weight: 700;
    line-height: 25px;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
  }

  .capabilitiesCardDescription{
    font-size: 16px;
    padding: 10px;
    font-weight: 400;
    width: 100%;
    text-align: start;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
  }

  .whatWeThinkContainer{
    background-color: rgb(239, 239, 239);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;
    width: 100%;
    /* border: 1px solid red;    */
}

.whatWeThinkSubContainer{
    width: 75%;
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: center; */
    border: 1px solid blue
}

.horizontalCardContainer{
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    gap: 10px;
    /* border: 1px solid pink */

}

.horizontalCard{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 40px;
    border: 1px solid black; 
}
.stratagyContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 10px;


}
.stratagy{
    font-size: 16px;
    font-weight: 400;
    text-align: start;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
}
.stratagyTitle{
    padding: 10px;
    font-size: 25px;
    font-weight: 700;
    line-height: 25px;
    text-align: start;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
}
.stratagyDescription{
    padding: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px; 
    text-align: start;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
}

.whatWeThinkContainerScroll{
    display: none;
}


@media (min-width: 992px) and (max-width: 1200px) {
    .mainContainer{
        justify-content: center;
        align-items: center;
    }
    .introContainer{
        justify-content: space-between;
        width: 90%;
        /* border: 1px solid rgb(130, 31, 48); */
    }
    .title{
        font-size: 30px;
        line-height: 40px;
         /* border: 1px solid pink; */
    }
    .heading{
        font-size: 25px;
        line-height: 30px;
         /* border: 1px solid pink; */
    }
    .subHeading{
        font-size: 30px;
    }
    .leftIntro{
        align-items: center;
        /* border: 1px solid rgb(130, 31, 48); */
    }
    .descriptionSubContainer{
        width: 90%;
    }
    .capabilitiesSubContainer{
        width: 90%;
    }
    .customCardContainer{
        width: 90%;
        
    }
    .cardsContainer{
        justify-content: center;
        
        gap: 20px;
    }
    .whatWeThinkSubContainer{
        width: 90%;
    }

}

@media (min-width:768px) and (max-width: 992px) {
    .mainContainer{
        align-items: center;
        justify-content: center;
    }
    .title{
        font-size: 30px;
        line-height: 40px;
         /* border: 1px solid pink; */
    }
    .heading{
        font-size: 25px;
        line-height: 30px;
         /* border: 1px solid pink; */
    }
    .subHeading{
        font-size: 30px;
    }
    .introContainer{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .leftIntro{
        align-items: center;
        /* border: 1px solid rgb(130, 31, 48); */
    }
    .rightIntro{
        margin-top: 20px;
        width: 85%;
            padding-right: 10px;
        text-align: center;
        /* border: 1px solid blue; */
    }
    .innerNav{
        height: 10vh;
        /* border: 1px solid blue; */
        width: 100%;
        text-align: center;
        justify-content: center;
    }
    .descriptionSubContainer{
        width: 90%;
    }
    .capabilitiesSubContainer{
        width: 90%;
    }
    
    .customCardContainer{
        width: 90%;
        
    }
    .cardsContainer{
        justify-content: center;
        
        gap: 10px;
    }
    .customCard{
        max-width: 300px;
    }
    .whatWeThinkSubContainer{
        width: 90%;
    }
}

@media (max-width: 768px) {
    .mainContainer{
        align-items: center;
        justify-content: center;
    }

    .title{
        font-size: 30px;
        line-height: 40px;
         /* border: 1px solid pink; */
    }
    .heading{
        font-size: 25px;
        line-height: 30px;
         /* border: 1px solid pink; */
    }
    .subHeading{
        font-size: 30px;
    }
    .introContainer{
        width: 95%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .leftIntro{
        align-items: center;
        /* border: 1px solid rgb(130, 31, 48); */
        width: 85%;
    }
    .rightIntro{
        margin-top: 20px;
        width: 85%;
            padding-right: 10px;
        text-align: center;
        /* border: 1px solid blue; */
    }
    .innerItem{
    width: 100%;
    text-align: center;
     border: 1px solid rgb(130, 31, 48);
    }
    .innerNav{
        height: 8vh;
        border: 1px solid blue;
        width: 90%;
        text-align: center;
        text-align: center;
        justify-content: center;
    }
    .descriptionSubContainer{
        width: 95%;
    }
    .capabilitiesSubContainer{
        width: 95%;
    }
    

   .customCardContainer {
    width: 95%;
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    /* border: 1px solid pink; */
    justify-content: flex-start; 
    padding: 10px 0; 
  }

   .cardsContainer {
        display: inline-flex; 
        flex: 0 0 auto; 
        margin-right: 10px; 
       width: auto;
       border: 1px solid black;
     }

     .customCard{
        max-width: 275px;
     }

    

     .whatWeThinkSubContainer{
        width: 95%;
    }
    .horizontalCard{
        flex-direction: column;
    }

    .whatWeThinkContainer{
        display: none;
    }


      .whatWeThinkContainerScroll{
        display: flex;
        flex-direction: column;
        width: 95%;
        align-items: center;

      }
      .whatWeThinkSubContainerScroll{
        display: flex;
        flex-direction: column;
        width: 95%;
        /* justify-content: center;*/
        align-items: center; 
      }


      .scrollable{
        overflow-x: auto;
         white-space: nowrap;
         border: 1px solid black;
         width: 80%;
         
      }
      

      .customCard > img {
        max-width: 95%;
        border-radius: 24px;
      }

     

   
   
    
}

