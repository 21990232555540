
.mainContainer{
    display: flex;
    justify-content: center;
    width: 100%;
    background: #000;
    padding: 40px 10px;
}
.container{
    width: 88%;
    display: flex;
    flex-direction: row;
    
}

.carouselContainer{
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 40px;
    background-color: #fff;
border-top-left-radius: 10px;
border-bottom-left-radius: 10px;


}
.carouselItem{
    padding: 0px 15px;
}
.testimonialContainer{
    width: 35%;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    
}
.testimonialTitle{
font-size: 33px;
font-weight: 700;
line-height: 46px;
letter-spacing: 0.20000000298023224px;
text-align: center;


}
.testimonialDescriptionWeb{

font-size: 15px;
font-weight: 400;
line-height: 21px;
letter-spacing: 0.20000000298023224px;
text-align: center;


}
.testimonialDescriptionMobile{
display: none;
font-size: 15px;
font-weight: 400;
line-height: 21px;
letter-spacing: 0.20000000298023224px;
text-align: left;


}

.testimonialImage{
    width: 100%;
}


.testimonial{
    font-size: 21px;
    font-weight: 300;
    line-height: 30px;
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 8; 
    -webkit-box-orient: vertical;
    
    min-height: 250px;

}
.authorContainer{
    margin-top: 40px;
}
.testimonialAuthor{
    font-size: 21px;
    font-weight: 700;
    line-height: 30px;
    margin: 0;
}




@media screen  and (min-width:993px) and (max-width:1199px) {

    .testimonial{
        font-size: 18px;
        -webkit-line-clamp: 9; 
        min-height: 280px;
    }
    .authorContainer{
        margin-top: 30px;
    }
}



@media screen  and (min-width:769px) and (max-width:992px) {
.container{
    flex-direction: column-reverse;
}
.testimonialContainer{
    width: 100%;
    padding: 10px;
    border-radius: 15px 15px 0px 0px;
}
.carouselContainer{
    width: 100%;
    padding:10px 10px 40px 10px;
    border-radius: 0px 0px 15px 15px;
   
}
.testimonialTitle{
    font-size: 33px;
    line-height: 30px;
    text-align: center;;
    
    
    }
.testimonialDescriptionWeb{
    font-size: 18px;
    line-height: 10px;
    
    }
.testimonialImage{
    display: none;
}
.authorContainer{
    margin-top: 30px;
}
    
}
@media screen  and (max-width:768px) {
    .container{
        flex-direction: column-reverse;
    }
    .testimonialContainer{
        width: 100%;
        padding: 10px;
        border-radius: 15px 15px 0px 0px;
        
    }
    .testimonialTitle{
        font-size: 33px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0.20000000298023224px;
        text-align: center;
        
        
        }
    .testimonialDescriptionWeb{
        display: none;
        font-size: 18px;
        font-weight: 400;
        line-height: 15px;
        text-align: center;
        
        }
    .testimonialDescriptionMobile{
        display: flex;
        font-size: 18px;
        font-weight: 400;
        line-height: 140%;
        text-align: center;
        
        }
    .carouselContainer{
        width: 100%;
        padding:10px 0px 40px 0px;
        border-radius: 0px 0px 15px 15px;
       
    }
    .testimonialImage{
        display: none;
    }
    .testimonial{
        font-size: 15px;
        line-height: 20px;
        -webkit-line-clamp: 12; 
        min-height: 240px;
    }
    .authorContainer{
        margin-top: 20px;
    }
    .testimonialAuthor{
        font-size: 15px;
        font-weight: 700;
        line-height: 22px;
        margin: 0;
    }

}