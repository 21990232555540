
.brand-logo {
    width: 50%; 
    /* max-width: 100px;  */
    height: auto;
    /* margin: 10px 0;  */
    margin-bottom: 10px;
  }

  @media screen and (max-width: 992px) {
    .brand-logo {
        width: 50vw; 
      }
  }