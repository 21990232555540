
.li-container ul {
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start; 
    justify-content: flex-start;
    
  }


.li-content{
    font-size: 16px;
    font-weight: 400;
    font-family: 'Lato' sans-serif;
    color: #303030;
    word-wrap: break-word;
    width: 100%;
    text-align: start;
    display: inline-block;
    vertical-align: middle;
    list-style-type: disc;
}
.blackdot{
  width: 8px;
  height: 8px;
  border-radius: 4px;
  color: #303030;
  background-color: #303030;
  margin-top: 10px;
}
 ul {
  list-style: disc;
  margin: 0px;
  padding: 0px;
}

  /* for tablets */
  @media screen and (max-width: 992px) {
    
  
    .li-content {
      width: 100%;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 2px;
    }
    .blackdot{
      width: 8px;
      height: 8px;
      border-radius: 4px;
      color: #000000;
      background-color: #000000;
      margin-top: 8px;
    }
}
  