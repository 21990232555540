
.mainContainer{
    display: flex;
    flex-direction: row;
    padding-top: 60px;
    /* padding: 20px; */
  }
  
  .leftSubContainer {
  width: 25%;
  position: sticky;
  top: 80px;
  height: calc(100vh - 80px);
  }
  
  .rightSubContainer{
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding:  40px ;
    border-left: 1px dotted gray;
  }
  /* .rightSubContainer:hover {
    animation: shake 5s;
    animation-iteration-count: 2;
    animation-timing-function: ease-in-out;

  }
  
  
  @keyframes shake {
    0% {
      transform: translateX(0) translateY(0);
      opacity: 1;
    }
    20% {
      transform: translateX(1px) translateY(1px) ;
      opacity: 0.9;
    }
    40% {
      transform: translateX(-1px) translateY(-1px);
      opacity: 0.8;
    }
    60% {
      transform: translateX(1px) translateY(1px) ;
      opacity: 0.7;
    }
    80% {
      transform: translateX(-1px) translateY(-1px);
      opacity: 0.9;
    }
    100% {
      transform: translateX(0) translateY(0);
      opacity: 1;
    }
  } */
  
  @media screen and (max-width: 992px) {
  
    .mainContainer {
      padding: 10px;
      margin-top: 15px;
    }
  
    .leftSubContainer{
      display: none;
  }
  
    .rightSubContainer {
        width: 100%;
      padding:80px 30px 30px 30px;
      justify-content: center;
      margin: auto;
      border: 0;
    }
  }
  
  
  
  
  