.mainContainer{
    margin-top: 5rem;
    /* height: calc(100vh-60px); */
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    /* border: 1px solid red; */

}
@media (min-width: 992px) and (max-width: 1200px) {
    .mainContainer{
        justify-content: center;
        align-items: center;
    }
}
@media (min-width:768px) and (max-width: 992px) {
    .mainContainer{
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .mainContainer{
        align-items: center;
        justify-content: center;
        height: auto;
    }
}