.scrollContainer{
    display: none;
}

@media (max-width: 768px) {
    .scrollContainer{
        /* padding: 0 10px; */
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        border: 1px solid red

      }
      .scrollSubContainer{
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        border: 1px solid black

      }
      .scrollableImageContainer{
        overflow-x: auto;
         white-space: nowrap;
         display: flex;
         flex-direction: column;
         width: 100%;

      }

      .scrollableImage{
        display: inline-flex;        
         flex: 0 0 auto; /* Allow cards to shrink when necessary */
         margin-right: 10px; 
        width: auto;

      }

      
      .scrollableImgSize{
        width: 100%;
        /* max-width: 280px; */
      }
      .scrollaableImgSize > img {
        width: 100%;
        max-width: 310px;
        margin-right: 10px;
      }

      
}