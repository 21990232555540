

.mainContainer{
    padding-top: 10rem;
    padding-bottom: 5rem;
    /* margin-top: 5rem; */
    gap: 40px;
    width: 100vw;
    height:100vh ;
    /* margin: auto; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.container{
    
    width: 100%;
    gap: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
  .BuildContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 50px;
   }
   .buildTitle{
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0px;
  text-align: center;
  vertical-align: bottom;
  padding-top: 15px;
  
   }

.animationContainer{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}

.animationLeft{
    position: absolute;
    width: 50vw;
    top: -50%;
    right: 50%;
}
.animationLeftImage { 
    position: absolute;
    height: 180px;
    width: 100%;
    right: 0;
    z-index: -2;
}
.animationRight{
    /* height: 200px; */
    position: absolute;
    width: 50vw;
    top: -50%;
    left: 50%;
}
.animationRightImage { 
    position: absolute;
    height: 180px;
    width: 100%;
    left: 0%;
    z-index: -2;
}
.rectangleImage{
    width: 55%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background: transparent; */
    mix-blend-mode: darken;
    z-index: -1;
}
.productTitleContainer{

    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: contain;
 }
 .productTitle{
    margin: 0;
    font-family: Lato;
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0px;
    text-align: center;
    color: #fff;
 }
 .bottomDescriptionContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 120px;
   }
 .bottomDescription{
    width: 95%;
    font-family: Lato;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;
    margin: 0;
 }
 .button{
    padding: 10px 30px;
    width: 15rem ;
    font-size: 18px;
    font-weight: 500;
    border: 1px solid #000;
    background-color: #fff;
    color: #000;
    border-radius: 50px;
 
  }
  .button:hover{
    background-color: #000;
    color: #fff;
}

 @media screen and (min-width:1601px) and (max-width:1800px) {
    .productTitle{
        font-size:35px;
font-weight: 700;
line-height: 38px;
    }
    .animationLeft{
        width: 40vw;
        
    }
    .animationLeftImage { 
        right: 25%;
    }
    .animationRight{
        width: 40vw;
        height: auto;

    }
    .animationRightImage { 
        left: 25%;
    }
   
 }
 @media screen and (min-width:1801px) and (max-width:2800px) {
    .productTitle{
        font-size:35px;
font-weight: 700;
line-height: 38px;
    }
    .rectangleImage{
        width: 40%;
       
    }
    .animationLeft{
        width: 38vw;
    }
    .animationLeftImage { 
        right: 30%;

    }
    .animationRight{
        width: 38vw;

    }
    .animationRightImage { 
        left: 30%;
    }
   
 }

 @media screen and (min-width:993px) and (max-width:1199px) {
    
    .productTitle{
        font-size: 28px;
font-weight: 700;
line-height: 38px;
    }
    .rectangleImage{
        width: 60%;
    } 
 }

 @media screen and (min-width:768px) and (max-width:992px) {
    
    .animationLeft{
        display: none;
    }
    .animationRight{
        display: none;
    }
    .BuildContainer{
        gap: 20px;
        align-items: flex-end;
    }
    .productTitleContainer{
        height: 80px;
    } 
    .productTitle{
        font-size: 28px;

    }
    .rectangleImage{
        width: 70%;
        height: 60px;
    }
    .buildTitle{
        font-size: 28px;
        
         }
         .bottomDescriptionContainer{
            gap: 20px;
        }
        .bottomDescription{
            width: 70%;
        }
 }

 @media screen and (max-width:768px) {
   
    .mainContainer{
        padding-top: 10rem;
        padding-bottom: 4rem;
        height:80vh
    }
    
    .animationLeft{
        display: none;
    }
    .animationRight{
        display: none;
    }
    .BuildContainer{
        gap: 10px;
        align-items: flex-end;
    }
    .buildTitle{
        font-size: 20px;
    }

    .productTitleContainer{
            height: 80px;
    } 
    .productTitle{
        font-size: 18px;
    }
    .rectangleImage{
        width: 95%;
        height: 60px;
    }
    .bottomDescriptionContainer{
        gap: 10px;
    }
    .bottomDescription{
        width: 85%;
        font-size: 15px;
    }
    .button{
        padding: 5px 10px;
        width: 10rem ;
        font-size: 12px;
      }
}
