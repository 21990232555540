
.mainContainer{
    display: flex;
    flex-direction: row;
    padding-top: 60px;
    /* padding: 20px; */
  }
  
  .leftSubContainer {
  width: 25%;
  position: sticky;
  top: 80px;
  height: calc(100vh - 80px);
  }
  
  .rightSubContainer{
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content:flex-start;
    padding:  40px ;
    border-left: 1px dotted gray;
    /* gap: 20px; */
  }
  
  @media screen and (max-width: 992px) {
  
    .mainContainer {
      padding: 10px;
      margin-top: 15px;
    }
  
    .leftSubContainer{
      display: none;
  }
  
    .rightSubContainer {
      width: 100%;
      padding:80px 30px 30px 30px;
      justify-content: center;
      margin: auto;
      border: 0;
    }
  }
  
  
  
  
  