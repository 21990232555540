
.leftSubContainer {
    width: 100%;
    position: sticky;
    top: 70px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 20px;
    /* height: calc(100vh-80px) !important; */
    overflow-y: auto; 

  }

.caseStudyButton{
    font-size: 18px;
    font-weight: 400;
    font-family: 'Lato' sans-serif;
    color: #000000;
    padding: 10px;
    background: transparent;
    border: none;
    border: 1px solid #979797;
    margin-bottom: 1rem;
    width: auto;  
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;  
}

.upArrow {
    width: 25px; 
    height: 25px; 
    margin-right: 5px; 
    padding: 4px;
    vertical-align: middle; 
    background-color: #000;
  }



.leftNavigation{
    font-size: 18px;
    font-weight: 400;
    font-family: 'Lato' sans-serif;
    color: #000000;
    opacity: 0.85;
    border: none;
    background: transparent;
   
}

  
  @media screen and (max-width: 768px) {

    .caseStudyButton {
      font-size: 16px;
      padding: 8px;
    }
  
    .caseStudy {
      font-size: 16px;
    }
  
    .left-navigation {
      font-size: 16px;
    }
  }
  

  
 