.loader {
    width: 60vw;
    height: 500px;
    margin-bottom: 10px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 5.5s infinite;
  }
  
  @keyframes loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }

.imageSliderContainer {
    width: 95%;
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start; 
    padding: 5px 5px; 
    scroll-behavior: smooth;
    scrollbar-width: thin;
    background: #ebebeb;
    margin-bottom: 10px;
    background-color: #fff;
  }

  .imageContainer {
    width: 85%;
    display: inline-flex; 
    flex: 0 0 auto; 
    gap: 20px;
 }

 
 .sliderImage{
    width: 100%;
    min-height: 400px;
    filter: grayscale(200%);
    transition: filter 300ms;
    /* max-height: 600px; */
   
 }
 .sliderImage:is(:hover, :focus){
   filter: grayscale(0%);
}

@media screen and (min-width:993px) and (max-width:1199px) {
  .loader{
    height: 371px;
    width: 90vw;
  }
  
}
@media screen and (min-width:769px) and (max-width:992px) {
  .loader{
    height: 371px;
    width: 100vw;
  }
  
}

 @media screen and  (max-width:768px) {
   .sliderImage{
      width: 100%;
      min-height: auto;
      max-height: 190px;
     
   }
   .loader{
    width: 80vw;
    height: 180px;
   }
 }