.customCard {
    width: 100%; 
    max-width: 320px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(239, 239, 239);
    box-shadow: 0px 46px 46px 0px #00000017;


  }

  .cardImageContainer {
    width: 100%;
    /* max-width: 320px;  */
    text-align: center; 
  }

/* .cardImageContainer > img {
   max-width: 100%;
   height: auto;
} */

.cardImage{
  width: 100%;
}
@media screen and (min-Width:769px) and (max-width:992px) {
  .customCard{
    min-width: 300px;
  }
  
}

@media screen and (max-Width:768px) {
  .customCard{
    min-width: 260px;
    max-width: 280px;
  }
  
}