.ourServices-background{
    background-color: #000000E8;
}
.title-text{
    font-size: 2.0625rem;
    font-weight: 700;
}
.text-base{
    font-size: 1.125rem;
    font-weight: 700;
    /* text-transform: uppercase; */
}
/* .textContent{
    font-size: 0.875rem;
    font-weight: 400;
    text-align: center;
} */

.textContent {
    font-size: 0.875rem;
    font-weight: 400;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines to show */
    -webkit-box-orient: vertical;
    /* max-height: 1.5rem; */
    min-height: 4.5rem;
  line-height: 1.5rem; /* Adjust based on your line height */

}

.logo{
    width: 42px;
    height: 42px;
}
.font-normal{
    font-size: 1rem;
    font-weight: 400;
}
/* .card:hover {
  cursor: pointer;
  background-color: black !important;
  color: white !important;
}

.card:hover .textContent  {
  color: white !important;
} */
.card:hover .logo  {
  scale: 1.1;
}
.card:hover .moreSvg  {
  animation: shake 5s ease-in-out infinite;
}
@keyframes shake {
  0%, 100% {
    transform: translateX(2px);
  }
  25% {
    transform: translateX(-2px);
  }
  50% {
    transform: translateX(2px);
  }
  75% {
    transform: translateX(-2px);
  }
}


@media (max-width: 991px) {
    .scrollable-container {
    
      overflow-x: auto;
      white-space: nowrap;
      
    }
    .scrollable-container .row {
      flex-wrap: nowrap;
    }
  }

  

  