

.background {
    background-color: #000000E8;
}

.title-text {
    font-size: 2.0625rem;
    font-weight: 700;
}

.products-text-base {
    font-size: 1.4rem;
    font-weight: 700;
    /* text-transform: uppercase; */
    font-family: 'Poppins';
    text-align: start;
}

/* .textContent {
    font-size: 0.875rem;
    font-weight: 400;
    text-align: start;
    color: #000000E5;
    line-height: 1.225rem;
} */
.textContent {
    font-size: 0.875rem;
    font-weight: 400;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
    min-height: 4.5rem;
    line-height: 1.5rem;

}

.logo {
     width: 54px;
    height: 54px; 
}

.font-normal {
    font-size: 1rem;
    font-weight: 400;
}

.custom-card {
    border: 12px solid #00000014;
    border-radius: 55px;
    height: 100%; /* Set a fixed height for the card */
    display: flex; /* Use flexbox to vertically center content */
    flex-direction: column; /* Stack content vertically */

    /* Center content horizontally and vertically */
    /* justify-content: center; */
    /* align-items: center; */
    
}
.placeholder {
    width: 60px;
    height: 60px;
    background-color: #fff; /* Use a background color to indicate the placeholder */
    /* margin: 0 auto; */
    margin-bottom: 24px;
}

.card {
    border: none;
}

.subtext {
    font-size: 0.75rem;
    font-weight: 400;
    text-align: center;
    margin-top: 0.5rem;
}

.user-logo {
    width: 53px;
    height: 30px;
}

.btn-dark {
    border-radius: 10px !important;
    background-color: black !important;
    color: #ffff !important;
}


.custom-card {
    cursor: pointer;
}

.custom-card:hover {
    cursor: pointer;
    background-color: black !important;
    color: white !important;
}

.custom-card:hover .textContent {
    color: white !important;
}

.custom-card:hover .btn-dark {
    background-color: white !important;
    color: black !important;
}
.nonClickable {
    cursor: not-allowed; /* Remove cursor pointer */
    pointer-events: none; /* Disable click functionality */
    opacity: 0.5;

  }
@media (max-width: 991px) {
    .scrollable-container {
      overflow-x: auto;
      white-space: nowrap;
      
    }
    .scrollable-container .row {
      flex-wrap: nowrap;
    }

  }

 
