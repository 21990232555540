
.mainContainer{
    margin-top: 5rem;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
    padding: 10px 0;
    /* border: 1px solid red; */

}

.introContainer{
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    justify-content: flex-end;
    align-items: flex-start;
    
}
.innerNav{
    display: flex;
    width: 100%;
    /* padding: 0px 20px; */
    height: 40vh;
    align-items: flex-start;
    justify-content: flex-end;
}

.innerItem{
    display: flex;
    flex-direction: column;
}

.leftIntro {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .rightIntro {
    width: 774px;
    padding: 0; 
  }
  .rightIntro > svg {
    width: 100%; 
    height: auto; 
  }

.title{
    font-size: 60px;
    font-weight: 600;
    line-height: 50px;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
}

.heading{
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
}

.subHeading{
    font-size: 50px;
    font-weight: 700;
    line-height: 16px;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
}

.description{
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    text-align: start;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
}

.brandContainer{
    width: 100%;
    background-color: rgb(239, 239, 239);
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}
.brandSubContainer{
    width: 75%;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    /* border: 1px solid blue; */
    
}
.brandDescriptionContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.circularCardContainer{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: flex-start;
    align-items: center;
    /* border: 1px solid pink; */
}

.circularCards{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.circularCardTitle{
    color: #fff;
    font-size: 20;
    font-weight: 600;
    line-height: 24px;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
}
.uxDesignContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid red;
}
.uxDesignSubContainer{
    width: 75%;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border: 1px solid blue;
}


.uxDescriptionContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.customCardContainer{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    border: 1px solid green;

}
.cardsContainer{
    width: 100%;
    display: flex;
    flex-wrap: wrap; /* Changed to wrap here */
    gap: 20px; /* Added gap for spacing between cards */
    /* border: 1px solid pink; */
}

.customCard {
    max-width: 330px;
    width: 100%; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(239, 239, 239);
    /* padding-top: 10px;  */
    padding-bottom: 20px;
    gap: 20px;
    box-shadow: 0px 4px 4px 0px #00000066;

  }

  .customCardImageContainer {
    width: 100%;
    max-width: 330px; 
    text-align: center; 
  }

.customCardImageContainer > img {
   max-width: 100%;
   height: auto;
}

.customCardTitle{
    font-size: 18px;
    font-weight: 600;
    word-wrap: break-word;
    white-space: normal; 
    overflow-wrap: break-word
    
}
.designThinkingContainer{
    width: 100%;
    background-color: rgb(239, 239, 239);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid red;
}
.designThinkingSubContainer{
    width: 75%;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    /* border: 1px solid blue; */
}
.designThinkingDescriptionContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.imageConatiner{
    display: flex ;
    flex-direction: row;
    width: 100%;
    /* border: 1px solid green; */

}

.thinkLeftContainer{
    width: 615px;
    
}

.img1Wrapper{
    width: 100%;
    max-width: 615px;
    padding: 5px;
    /* border: 1px solid red; */
}

.img1Wrapper > img {
    width: 100%; 
    /* max-width: 615px; */
    height: auto; 
  }

.thinkRightContainer{
    width: 435px;
}
.img2Wrapper{
    width: 100%;
    max-width: 435px;
    padding: 5px;
    /* border: 1px solid red; */
}
.img2Wrapper > img {
    width: 100%; 
    /* max-width: 615px; */
    height: auto; 
  }

  .scrollContainer{
    display: none;
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    .mainContainer{
        justify-content: center;
        align-items: center;
    }
    .introContainer{
        justify-content: space-between;
        width: 90%;
        /* border: 1px solid rgb(130, 31, 48); */
    }
    .title{
        font-size: 30px;
        line-height: 40px;
         /* border: 1px solid pink; */
    }
    .heading{
        font-size: 25px;
        line-height: 30px;
         /* border: 1px solid pink; */
    }
    .subHeading{
        font-size: 30px;
    }
    .leftIntro{
        align-items: center;
        /* border: 1px solid rgb(130, 31, 48); */
    }
    .brandSubContainer{
        width: 90%;
    }
    .uxDesignSubContainer{
        width: 90%;
    }
    .customCard {
        max-width: 280px;
        gap: 12px;
      }
    
      .customCardImageContainer {
        max-width: 280px; 
      }

    .designThinkingSubContainer{
        width: 90%;
    }
    .scrollContainer{
        display: none;
    }
} 

@media (min-width:768px) and (max-width: 992px) {
    .mainContainer{
        align-items: center;
        justify-content: center;
    }
    .title{
        font-size: 30px;
        line-height: 40px;
         /* border: 1px solid pink; */
    }
    .heading{
        font-size: 25px;
        line-height: 30px;
         /* border: 1px solid pink; */
    }
    .subHeading{
        font-size: 30px;
    }
    .introContainer{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .leftIntro{
        align-items: center;
        /* border: 1px solid rgb(130, 31, 48); */
    }
    .rightIntro{
        margin-top: 20px;
        width: 85%;
            padding-right: 10px;
        text-align: center;
        /* border: 1px solid blue; */
    }
    .innerNav{
        height: 10vh;
        /* border: 1px solid blue; */
        width: 100%;
        text-align: center;
        justify-content: center;
    }
    .brandSubContainer{
        width: 90%;
    }
    .uxDesignSubContainer{
        width: 90%;
    }

    .uxDescriptionMainContainer{
        width: 100%;
        padding: 20px 0;
        display: flex;
        /* flex-direction: column; */
        align-items: center;
        justify-content: center;
        gap: 10px;
        border: 1px solid blue;
    }

    

    .designThinkingSubContainer{
        width: 90%;
    }
    .scrollContainer{
        display: none;
    }
    .cardsContainer{
        justify-content: center;
    }
}


@media (max-width: 768px) {
    .mainContainer{
        align-items: center;
        justify-content: center;
    }

    .title{
        font-size: 30px;
        line-height: 40px;
         /* border: 1px solid pink; */
    }
    .heading{
        font-size: 25px;
        line-height: 30px;
         /* border: 1px solid pink; */
    }
    .subHeading{
        font-size: 30px;
    }
    .introContainer{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
    
    }
    .leftIntro{
        align-items: center;
        /* border: 1px solid rgb(130, 31, 48); */
        width: 85%;
    }
    .rightIntro{
        margin-top: 20px;
        width: 85%;
            padding-right: 10px;
        text-align: center;
        /* border: 1px solid blue; */
    }
    .innerNav{
        height: 8vh;
        /* border: 1px solid blue; */
        width: 90%;
        text-align: center;
        justify-content: center;
    }
    .brandSubContainer{
        width: 90%;
    }
    .designThinkingContainer{
        display: none;
    }

    .circularCardContainer{
        gap: 10px;
        justify-content: center;
        align-items: center;
    }

    .circularCards{
        width: 160px;
        height: 160px;
        align-items: center;
    }
    .circularCardTitle{
        
        font-size: 14;
        
        line-height: 20px;
        
    }
    .uxDesignSubContainer{
        width: 90%;
    }
    .customCardContainer{
        overflow-x: auto;
         white-space: nowrap;
         display: flex;
         flex-direction: row;
         justify-content: flex-start;
         /* width: 100%; */
    }
    .cardsContainer{
        display: inline-flex;        
         flex: 0 0 auto; /* Allow cards to shrink when necessary */
         margin-right: 10px; 
        width: auto;
        justify-content: flex-start;
    }

    .scrollContainer{
        display: flex;
        flex-direction: column;
        width: 90%;
      }
      .scrollableImageContainer{
        overflow-x: auto;
         white-space: nowrap;
         display: flex;
         flex-direction: column;
         width: 100%;
      }
      .scrollaableImgSize{
        max-width: 280px;
      }
      .scrollaableImgSize > img {
        width: 100%;
        margin-right: 10px;
      }

      .scrollableImage{
        display: inline-flex;        
         flex: 0 0 auto; /* Allow cards to shrink when necessary */
         margin-right: 10px; 
        width: auto;

      }
     

      
     
     
}