.descriptionContainer{
    background-color: rgb(239, 239, 239);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 30px 0px 30px;
    width: 100%;
    
    /* border: 1px solid black; */
}
.descriptionSubContainer{
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
/* padding-bottom: 5rem; */

    /* border: 1px solid red; */
}


@media (min-width: 992px) and (max-width: 1200px) {
    .descriptionSubContainer{
        width: 90%;
    }
}
@media (min-width:768px) and (max-width: 992px) {
    .descriptionSubContainer{
        width: 90%;
    }
}
@media (max-width: 768px) {

    .descriptionContainer{
        padding: 0px;
        padding-top: 10px;
    }
    .descriptionSubContainer{
        width: 90%;
    }
}