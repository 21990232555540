.skeleton-image {
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: skeletonLoading 5.5s infinite;
}

@keyframes skeletonLoading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.caseStudyBanner{
    width: 100%;
    height: 100%;
    filter: grayscale(200%);
    transition: filter 300ms;
}
.caseStudyBanner:is(:hover, :focus){
  filter: grayscale(0%);
}
.text-base2{
    font-size: 1.8rem;
    font-weight: 700;
    color: #000000BD;
    font-family: 'Poppins';
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to show */
    -webkit-box-orient: vertical;
    min-height: 3rem;
  line-height: 1.8rem;;

}
.custom-card:hover .text-base2 {
    color: white !important;
}

.__caseStudy{
    font-weight: 700;
    font-size: 0.875rem;
    font-family: 'poppins' ;
    text-align: center;
}
.textContent{

    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #000000BD;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines to show */
    -webkit-box-orient: vertical;
    min-height: 4.5rem;
  line-height: 1.5rem;
}

.ViewMore-container{
  display: flex;
  justify-content: center;
  align-items: flex-end;
  
}

.view-more-button {
  color: #000000;
  border: none;
  padding: 5px 40px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 20px;
  border-radius: 4px;
  border: 2px solid #000000;
}

.view-more-button:hover {
  background-color: #f1ebeb
}

@media screen and (min-width:993px) and (max-width:1199px) {

}

@media (max-width: 991px) {
    .scrollable-container {
      overflow-x: auto;
      white-space: nowrap;
      
    }
    .scrollable-container .row {
      flex-wrap: nowrap;
    }
    .text-base2{
      font-size: 1.25rem;

    }
    .__caseStudy{
      font-size: 0.75rem;
    }
    .textContent{
      font-size: 12px;
      
    }
  }

