.capabilitiesContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 30px;
    width: 100%;
    background-color: rgb(239, 239, 239);
    
    /* border: 1px solid red; */
}

.capabilitiesSubContainer{
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    
    /* border: 1px solid red; */
}

.customCardContainer{
    width: 75%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    /* border: 1px solid green; */
}
.cardsContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap; /* Changed to wrap here */
    gap: 15px; /* Added gap for spacing between cards */
    /* border: 1px solid pink; */
  }


  @media (min-width: 992px) and (max-width: 1200px) {
    .capabilitiesSubContainer{
        width: 90%;
    }
    .customCardContainer{
        width: 90%;
        
    }
    .cardsContainer{
        justify-content: center;
        
        gap: 20px;
    }
  }

  @media (min-width:768px) and (max-width: 992px) {

.capabilitiesSubContainer{
    width: 90%;
}

.customCardContainer{
    width: 90%;
    
}
.cardsContainer{
    justify-content: center;
    
    gap: 10px;
}
.customCard{
    max-width: 300px;
}
  }

  @media (max-width: 768px) {

    .capabilitiesContainer{
        padding: 0px;
    }
    .capabilitiesSubContainer{
        width: 90%;
    }
    

   .customCardContainer {
    width: 95%;
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    /* border: 1px solid pink; */
    justify-content: flex-start; 
    padding: 10px 0; 
  }

   .cardsContainer {
        display: inline-flex; 
        flex: 0 0 auto; 
        margin-right: 10px; 
       width: auto;
     }

     .customCard{
        max-width: 275px;
     }
  }