.WorkshopContainer{
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
    width: 100%;
}

.WorkshopSubContainer{

    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
      /* border: 1px solid pink; */
    }

.cardsContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap; /* Changed to wrap here */
    gap: 10px; 
    /* border: 1px solid green; */
  }
  .scrollableContainer {
    
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    
  }
  .anchorTag{
    text-decoration: none;
    color: #000;
  }
  .cardWraper{
    padding: 5px;
    
}
.marketingCard{
    padding: 0px 3px 10px 3px;
    width: 310px;
    /* height: 240px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
    box-shadow: 0px 4px 4px 0px #00000040;

    
}

.marketingTitle{
    font-size: 26px;
    font-weight: 700;
    word-wrap: break-word;
    white-space: normal; 
    overflow-wrap: break-word;
    margin: 0;
    
}
.marketingImageContainer{
    width: 100%;
    /* border: 1px solid red; */
    
}
.marketingImage{
    width: 100%;
    padding: 0px;
    margin: 0px;
    
}
.publishBy{
    font-size: 14px;
    font-weight: 600;
    color: #EAEAEA;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
    margin: 0;
    padding: 0px 0px 0px 5px;  
}
.publishTitle{
    font-size: 18px;
    font-weight: 600;
    color: #000;
    word-wrap: break-word; 
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
    text-align: start; 
    margin: 0;
    padding: 0px 0px 0px 5px;
    
}
.publishDescription{
    font-size: 16px;
    font-weight: 400;
    text-align: start;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
    margin: 0;
    padding: 5px 0;
    
}


@media (min-width: 992px) and (max-width: 1200px) {
    .WorkshopContainer{
        justify-content: center;
        width: 100%;
        /* border: 1px solid pink; */
    }
    .WorkshopSubContainer{
        width: 95%;

    }
    .cardsContainer {
        width: 100%;
        display: flex;
        flex-wrap: wrap; /* Changed to wrap here */
        gap: 10px; 
        justify-content: center;
        /* border: 1px solid green; */
      }
}

@media (min-width:768px) and (max-width: 992px) {
    .WorkshopSubContainer{
        width: 90%;
        /* border: 1px solid yellow; */
    }
    .cardsContainer {
        display: inline-flex;
        flex: 0 0 auto; /* Allow cards to shrink when necessary */
        margin-right: 10px; 
        width: auto;
      }
      .scrollableContainer{
        overflow-x: auto;
        white-space: nowrap;
        display: flex;
    }
    .cardsContainer {
        display: inline-flex;
        flex: 0 0 auto; /* Allow cards to shrink when necessary */
        margin-right: 10px; 
        width: auto;
      }
}

@media (max-width: 768px) {
    .WorkshopSubContainer{
        width: 95%;
        /* border: 1px solid yellow; */
    }
    .scrollableContainer{
        overflow-x: auto;
        white-space: nowrap;
        display: flex;
   }
   .cardsContainer {
        display: inline-flex; 
        flex: 0 0 auto; /* Allow cards to shrink when necessary */
        margin-right: 10px; 
       width: auto;
     }
     .marketingCard{
        width: 270px;
     }
     .publishBy{
        font-size: 12px;
         
    }
     .publishTitle{
        font-size: 14px;
      
        
    }
}