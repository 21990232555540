.scrollableImageContainer{
    overflow-x: auto;
     white-space: nowrap;
     display: flex;
     flex-direction: column;
     width: 100%;
  }
  .scrollableImage{
    display: inline-flex;        
     flex: 0 0 auto; /* Allow cards to shrink when necessary */
     margin-right: 10px; 
    width: auto;

  }
  .scrollaableImgSize{
    max-width: 280px;
  }
  .scrollaableImgSize > img {
    width: 100%;
    margin-right: 10px;
  }

  