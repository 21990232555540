
.mainContainer{
  margin-top: 7rem;
  height: calc(100vh-60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}
.innerNav{
  display: flex;
  width: 75%;
  padding: 0px 20px;
}
.introContainer{
  display: flex;
  flex-direction: row;
  padding: 10px 30px;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  justify-content: space-between;
  align-items: center;
  width: 75%;
  /* align-items: center; */
  
}


.leftIntro {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.leftIntro > svg {
  width: 70px;
  height: 70px
}

.skalebotTitle{
  font-size: 59px;
  font-weight: 700;
  line-height: 88.5px;
  font-family: 'Lato' sans-serif;
  text-align: start;
  /* word-wrap: break-word; */
}
.skalebotSubTitle{
  font-size: 39px;
  font-weight: 500;
  line-height: 58.5px;
  font-family: 'Lato' sans-serif;
  text-align: start;
  
}
.skalebotContent{
  font-size: 18px;
  font-weight: 400;
  text-align: start;
  line-height: 32.4px;

}
.buttonContainer{
  display: flex;
  flex-direction: row;

}
.button{
  background: none;
  border:1px solid #000;
  border-radius: 10px;
  padding: 5px 30px;
  font-size: 14px;
  font-weight: 500;
}
.benifitsContainer{
  width: 75%;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}
.benifitsHeading{
  font-size: 36px;
  font-weight: 700;
  font-family: 'Poppins';
}
.cardsContainer{
  width: 100%;
  
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  
}
.cardWraper{
  padding: 5px;
  
}

.customCard{
  padding: 20px 0;
  width: 340px;
  /* height: 240px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FAFAFA;
  
  
}
.cardTitle{
  font-size: 18px;
  font-weight: 700;
  
}
.cardDescription{
  font-size: 16px;
  font-weight: 300;
  width: 100%;
  word-wrap: break-word;
  white-space: normal; /* Ensure normal white-space handling */
  overflow-wrap: break-word;
}

.videoContainer{
  width: 75%;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  max-width: 100%; 
margin: 0 auto; 
}
.videoTitle{
  font-size: 40px;
  font-weight: 700;
}
.videoText{
  font-size: 29px;
  font-weight: 400;
}
iframe {
  max-width: 100%; 

}

.marketingContainer{
  width: 75%;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.marketingCard{
  padding: 10px 3px;
  width: 330px;
  /* height: 240px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
  
}

.marketingTitle{
  font-size: 36px;
  font-weight: 700;
  word-wrap: break-word;
  white-space: normal; 
  overflow-wrap: break-word;
  margin: 0;
  
}
.marketingImageContainer{
  width: 100%;
  
}
.marketingImage{
  width: 100%;
  
}
.publishBy{
  font-size: 14px;
  font-weight: 600;
  color: #EAEAEA;
  word-wrap: break-word;
  white-space: normal; /* Ensure normal white-space handling */
  overflow-wrap: break-word;
  margin: 0;
  padding: 5px 0;
 
}
.publishTitle{
  font-size: 24px;
  font-weight: 600;
  color: #000;
  word-wrap: break-word; 
  white-space: normal; /* Ensure normal white-space handling */
  overflow-wrap: break-word;
  text-align: start; 
  margin: 0;
  padding: 5px 0;
  
}
.publishDescription{
  font-size: 16px;
  font-weight: 400;
  text-align: start;
  word-wrap: break-word;
  white-space: normal; /* Ensure normal white-space handling */
  overflow-wrap: break-word;
  margin: 0;
  padding: 5px 0;
  
}
.badgesContainer{
  display: flex ;
  flex-direction: row;
  padding: 5px 0;

}
.badges{
  background-color: #f1edfc;
  color: #6941C6;
  padding: 2px 10px;
  font-size: 14px;
  font-weight: 500;
}
.whatsappContainer{
margin-top: 10px;
padding-top: 10px;
padding-bottom: 10px;
padding: 10px 25px;
  display: flex;
  flex-direction: row;
  /* padding: 10px; */
  justify-content: space-around;
  align-items: center;
  width: 75%;
 
}
.qrDetails{
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  align-items:flex-start;
  /* width: 75%; */
  
}
.qrTitle{
  font-size: 70px;
  font-weight: 400;
  word-wrap: break-word;
  text-align: start;
  white-space: normal; /* Ensure normal white-space handling */
  overflow-wrap: break-word;
  /* width: 70%; */
}
.qrSubTitle{
  font-size: 28px;
  font-weight: 700;
  word-wrap: break-word;
  white-space: normal; /* Ensure normal white-space handling */
  overflow-wrap: break-word;
  text-align: start;
}
.qrmobileTitle{
  font-size: 14px;
  font-weight: 400;
  color: #7B6DF8;
}
.storeContainer{
  display: flex;
  flex-direction: row;
  /* padding: 10px; */
  /* justify-content: space-around; */
  align-items: center;
}
.contactUsContainer{
display: flex;
flex-direction: column;
align-items: center;
width: 75%;
padding-top: 10px;
padding-bottom: 10px;
margin-top: 10px;
}
.contactUsTitle{
  font-size: 40px;
  font-weight: 700;
  word-wrap: break-word;
}

.formContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 5rem;
  padding: 30px;
  
}

.inputDiv{
  width: 328px;
  height: 40px;
}

.inputStyle{
  width: 100%;
  padding: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19.6px;
  color: #FFFFFF;
  opacity: 0.7;
  background-color: black;
  border: 1px solid #000;
  /* outline-color: black; */
  
}

.submitButton{
  font-weight: 500;
  font-size: 14px;
  color: #FFF;
  background-color: #000;
  border: 1px solid #000;
  border-radius: 10px;
  margin-left: 20px;
  padding: 5px 20px ;

}


@media (max-width: 992px) {
.introContainer,.innerNav, .benifitsContainer, .videoContainer, .marketingContainer, .whatsappContainer, .contactUsContainer {
  width: 90%;
  
}
.innerNav{
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.introContainer{
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}
/* .rightIntro{
  padding: 20px;
} */

.rightIntro {
  padding: 0; 
  margin-top: 20px; 
}
.rightIntro > img {
  width: 100%; 
  height: auto; 
}

.skalebotTitle {
  font-size: 40px;
}

.skalebotSubTitle {
  font-size: 30px;
}

.customCard, .marketingCard {
  width: 280px;
}

.scrollableContainer {
  
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  
}


.cardsContainer {
  display: inline-flex;
  flex: 0 0 auto; /* Allow cards to shrink when necessary */
  margin-right: 10px; 
  width: auto;
}

.whatsappContainer{
  flex-direction: column-reverse;

}
.qrDetails{
  align-items: center;

}
.qrTitle{
  font-size: 45px;
  text-align: center;
}
.formContainer{
  flex-direction: column;
}
.submitButton{
  width: 50%;
  margin-left: 0;
}

}

@media (min-width: 769px) and (max-width: 991px) {

.innerNav{
  width:50%;
  align-items: center;
  justify-content: center;
}

  /* .introContainer{
      flex-direction: column;
      
  }
.skalebotTitle {
  font-size: 45px;
}

.skalebotSubTitle {
  font-size: 35px;
}

.customCard, .marketingCard {
  width: 300px;
}

.cardsContainer {
  overflow-x: auto; 
  white-space: nowrap; 
}

.cardWraper {
  flex: 0 0 auto; 
  margin-right: 10px; 
}

.customCard,
.marketingCard {
  min-width: 300px; 
} */


}

@media (min-width: 992px) and (max-width: 1199px) {
.innerNav{
  width: 60%;
  /* padding-left: 175px; */
}
  .introContainer{
      flex-direction: column;
      
  }
  .rightIntro{
      padding: 20px;
  }

.skalebotTitle {
  font-size: 50px;
}

.skalebotSubTitle {
  font-size: 40px;
}
}

@media (min-width: 1200px) {
.skalebotTitle {
  font-size: 55px;
}

.skalebotSubTitle {
  font-size: 45px;
}
}
