/* .circularCardContainer{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: start;
    align-items: center;
    border: 1px solid pink;
} */

.circularCards{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.circularCardTitle{
    margin: 0px;
    color: #fff;
    font-size: 20;
    font-weight: 600;
    line-height: 24px;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
    vertical-align: middle;
}

@media (max-width: 768px) {

/* .cirx */

.circularCards{
    width: 160px;
    height: 160px;
    align-items: center;
}
.circularCardTitle{
    
    font-size: 14px;
    line-height: 20px;
    
}
}