
.designThinkingContainer{
    width: 100%;
    background-color: rgb(239, 239, 239);
    display: flex;
    justify-content: center;
    align-items: center;
}
.designThinkingSubContainer{
    width: 75%;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}
.designThinkingDescriptionContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.imageConatiner{
    display: flex ;
    flex-direction: row;
    width: 100%;

}


.thinkLeftContainer{
    width: 60%;
    display: flex;
  flex-direction: column;
  gap: 10px;
    
}

.img1Wrapper{
    width: 100%;
    padding: 5px;
}

.img1Wrapper > img {
    width: 100%; 
    /* max-width: 615px; */
  }

.thinkRightContainer{
    width: 40%;
    height: auto;
    display: flex;
  flex-direction: column;
  gap: 10px;
}
.img2Wrapper{
    width: 100%;
    padding:5px;
}
.img2Wrapper > img {
    width: 100%; 
    height: 100%;
  }
  
  /* Define the height ratios for images in the right container */
.img2Wrapper:first-child {
    flex-grow: 0.6; /* 60% height */
    height: 100%;
  }
  
  .img2Wrapper:last-child {
    flex-grow: 0.4; /* 40% height */
    height: 100%;
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    .designThinkingSubContainer{
        width: 90%;
    }
  }

  @media (min-width:768px) and (max-width: 992px) {
    .designThinkingSubContainer{
        width: 90%;
    }
  }

  @media (max-width: 768px) {
    .designThinkingContainer{
        display: none;
    }
  }