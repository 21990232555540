.whatWeThinkContainerScroll{
    display: none;
}

@media (max-width: 768px) {

    .whatWeThinkContainerScroll{
        display: flex;
        flex-direction: column;
        width: 90%;
        align-items: center;

      }
      .whatWeThinkSubContainerScroll{
        display: flex;
        flex-direction: column;
        width: 95%;
        /* justify-content: center;*/
        align-items: flex-start; 
      }

    .customCardContainer {
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
        display: flex;
        /* border: 1px solid pink; */
        justify-content: flex-start; 
        padding: 10px 0; 

      }
    
       .cardsContainer {
            display: inline-flex; 
            flex: 0 0 auto; 
            margin-right: 10px; 
            padding: 10px;
           width: auto;
           gap: 10px;
           /* border: 1px solid black; */
         }

}