
.introContainer{
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    /* justify-content: flex-end; */
    align-items: flex-start;
    column-gap: 40px;
    
}
.leftIntro {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 40%;
  }

  .innerNav{
    display: flex;
    height: 30vh;
    align-items: flex-start;
    justify-content:  flex-end !important ; 
    
    
}

.innerItem{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.spanTitle{
    margin: 0;
font-size: 32px;
font-weight: 500;
line-height: 45px;
letter-spacing: 0px;
color: #fff;
background-color: #000;
border-bottom-right-radius: 24px;
padding: 1px 15px;
text-align: start;
width: fit-content


}

.heading{
    padding: 0;
    margin: 0;
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
    width: 100%;
    text-align: start;
    
}


.rightIntro {
    width: 60%;
    /* height: 80vh; */
    height: auto;
  }

  .imageClass{
    width: 95%;
    height: 100%;
  }
 

  @media (min-width: 992px) and (max-width: 1200px) {
    .introContainer{
        width: 90%;
    }

    .leftIntro{
        width: 40%;
        align-items: center;
    }
    .rightIntro{
        height: 70vh;
        width: 100%;
    }
    .innerNav{
        height: 20vh;
        
    }
    
  .imageClass{
    width: 100%;
  }
  .spanTitle{
font-size: 28px;
line-height: 35px;
}

.heading{
    font-size: 35px;
    line-height: 35px;
}
  }

  @media (min-width:768px) and (max-width: 992px) {
    .introContainer{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .leftIntro{
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .innerItem{
        width: 100%;
        
    }


    .innerNav{
        margin-bottom: 20px;
    height: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    }

    .rightIntro{
    margin-top: 20px;
    width: 100%;
    height: 100%;
    padding-right: 10px;
    text-align: center;
    }
    .imageClass{
        width: 100%;
      }   
    .spanTitle{
        font-size: 28px;
        line-height: 35px;
        width: fit-content;
        }
        
        .heading{
            font-size: 35px;
            line-height: 35px;
           
        }
  }

  @media (max-width: 768px) {
    .introContainer{
        width: 95%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .leftIntro{
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .innerItem{
    width: 100%;
    align-items: center;
    
    }
    .innerNav{
        margin-bottom: 20px;
        height: auto;
        display:flex;
        text-align: center ;
        justify-content:  center ;
        align-items: center  ;
    }
    
    .rightIntro{
        margin-top: 20px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .spanTitle{
        font-size: 20px;
        line-height: 25px;
        width: auto;
        text-align: center;
        
        }
        
        .heading{
            font-size: 25px;
            line-height: 25px;
            text-align: center;
           
        }
  }