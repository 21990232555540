.mainContainer{
    margin-top: 5rem;
    /* height: calc(100vh-60px); */
    display: flex;
    flex-direction: column;
    padding: 10px 0;
}
.introContainer{
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    justify-content: flex-end;
    align-items: flex-start;
    
}
.innerNav{
    display: flex;
    width: 100%;
    height: 40vh;
    align-items: flex-start;
    justify-content:flex-end;
}

.innerItem{
    display: flex;
    flex-direction: column;
}

.leftIntro {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .rightIntro {
    width: 830px;
    padding: 0; 
  }
  .rightIntro > svg {
    width: 100%; 
    height: auto; 
  }

.title{
    font-size: 60px;
    font-weight: 700;
    line-height: 50px;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
}

.heading{
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
}

.subHeading{
    font-size: 40px;
    font-weight: 600;
    line-height: 16px;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
}

.description{
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    text-align: start;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
}

.descriptionContainer{
    background-color: rgb(239, 239, 239);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;
}
.descriptionSubContainer{
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.ourWorkContainer{
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ourWorkSubContainer{

    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}



.cardsContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap; /* Changed to wrap here */
    gap: 10px; /* Added gap for spacing between cards */
  }


.cardWraper {
    width: 100%; /* Set the width to 100% for even spacing */
    max-width: 330px; /* Set the maximum width of each card */
    flex: 1; /* Allow cards to grow and fill available space */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }


.customCard {
    width: 100%; 
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(239, 239, 239);
  }


.cardImageContainer {
    width: 100%;
    max-width: 330px; 
    text-align: center; 
  }

.cardImageContainer > img {
   max-width: 100%;
   height: auto;
}

.cardDetails{
    width: 100%;
    padding: 10px 30px;

}

.cardTitle{
    font-size: 18px;
    font-weight: 600;
    word-wrap: break-word;
    white-space: normal; 
    overflow-wrap: break-word
    
}
.cardDescription{
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    word-wrap: break-word;
    white-space: nowrap; 
    /* overflow-wrap: break-word; */
    overflow: hidden;
    text-overflow: ellipsis;
    
  max-height: 40px; 
}


.whatWeThinkContainer{
    background-color: rgb(239, 239, 239);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;
    /* border: 1px solid red;    */
}

.whatWeThinkSubContainer{
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.imageConatiner{
    display: flex ;
    flex-direction: row;
    width: 100%;
}

.thinkLeftContainer{
    width: 615px;
    
}

.img1Wrapper{
    width: 100%;
    max-width: 615px;
    padding: 5px;
    /* border: 1px solid red; */
}

.img1Wrapper > img {
    width: 100%; 
    /* max-width: 615px; */
    height: auto; 
  }

.thinkRightContainer{
    width: 435px;
}
.img2Wrapper{
    width: 100%;
    max-width: 435px;
    padding: 5px;
    /* border: 1px solid red; */
}
.img2Wrapper > img {
    width: 100%; 
    /* max-width: 615px; */
    height: auto; 
  }

.scrollContainer{
    display: none;
}

  @media (min-width: 992px) and (max-width: 1200px) {

    .mainContainer{
        justify-content: center;
        align-items: center;
    }
    .introContainer{
        justify-content: space-between;
        width: 90%;
    }
    .title{
        font-size: 40px;
        line-height: 40px;
    }
    .heading{
        font-size: 30px;
        line-height: 30px;
    }
    .leftIntro{
        align-items: center;
    }

    .rightIntro {
    width: 600px;
    padding: 0; 
  }
.descriptionContainer{
    justify-content: center;
    width: 100%;
    /* border: 1px solid pink; */
        
}

.descriptionSubContainer{
    width: 95%;
}

.description{
    line-height: 30px;
}
.ourWorkContainer{
    justify-content: center;
    width: 100%;
    /* border: 1px solid pink; */
}
.ourWorkSubContainer{
    width: 95%;
}
.whatWeThinkContainer{
    width: 100%;
}
.whatWeThinkSubContainer{
    width: 95%;
    /* border: 1px solid red; */
}


  }

  @media (min-width:768px) and (max-width: 992px) {
    .mainContainer{
        align-items: center;
        justify-content: center;
    }

    .introContainer{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .innerItem{
    width: 100%;
}
    .title{
        font-size: 40px;
        line-height: 40px;
        width: 100%;
        text-align: center;
        /* border: 1px solid red; */
    }
    .heading{
        font-size: 30px;
        line-height: 30px;
    }

  .innerNav{
    height: 10vh;
    /* border: 1px solid blue; */
    width: 100%;
    text-align: center;
    justify-content: center;
}

.rightIntro{
    margin-top: 20px;
    width: 85%;
        padding-right: 10px;
    text-align: center;
}

.descriptionContainer{
    justify-content: center;
        
}

.descriptionSubContainer{
    width: 90%;
}

.description{
    font-size: 15px;
    line-height: 30px;
}

.scrollableContainer{
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
}
.cardsContainer {
    display: inline-flex;
    flex: 0 0 auto; /* Allow cards to shrink when necessary */
    margin-right: 10px; 
    width: auto;
  }
  .ourWorkSubContainer{
        width: 90%;
        /* border: 1px solid yellow; */
    }

    .whatWeThinkContainer{
        width: 100%;
    }
    .whatWeThinkSubContainer{
        width: 90%;
        /* border: 1px solid red; */
    }


  }

@media (max-width: 768px) {
    .mainContainer{
        align-items: center;
        justify-content: center;
    }

    .introContainer{
        width: 95%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .innerItem{
    width: 100%;
    
}
    .title{
        font-size: 40px;
        line-height: 40px;
        width: 100%;
        text-align: center;
        /* border: 1px solid red; */
    }
    .heading{
        font-size: 30px;
        line-height: 30px;
    }
    .innerNav{
        height: 6vh;
        /* border: 1px solid blue; */
        width: 100%;
        text-align: center;
        justify-content: center;
    }
    
    .rightIntro{
        margin-top: 20px;
        width: 85%;
        padding-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .descriptionContainer{
        justify-content: center;
            
    }
    
    .descriptionSubContainer{
        width: 95%;
    }
    
    .description{
        font-size: 15px;
        line-height: 30px;
    }
    .ourWorkSubContainer{
        width: 95%;
        /* border: 1px solid yellow; */
    }
    .cardWraper{
        max-width: 250px;
    }
    .scrollableContainer{
         overflow-x: auto;
         white-space: nowrap;
         display: flex;
    }
    .cardsContainer {
         display: inline-flex; 
         flex: 0 0 auto; /* Allow cards to shrink when necessary */
         margin-right: 10px; 
        width: auto;
      }

      .whatWeThinkContainer{
        display: none;
      }
      .scrollContainer{
        display: flex;
        flex-direction: column;
        width: 80%;
      }
      .scrollableImageContainer{
        overflow-x: auto;
         white-space: nowrap;
         display: flex;
         flex-direction: column;
         width: 100%;
      }
      .scrollaableImgSize{
        max-width: 280px;
      }
      .scrollaableImgSize > img {
        width: 100%;
        margin-right: 10px;
      }

      .scrollableImage{
        display: inline-flex;        
         flex: 0 0 auto; /* Allow cards to shrink when necessary */
         margin-right: 10px; 
        width: auto;

      }


}


