.container{
background-color: #fff;
display: flex;
align-items: center;
justify-content: center;
/* padding: 5rem; */
}

.subContainer{
    background-color: #Fff;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 20px;
    border-radius: 55px;
}

@media screen and (min-width:769px ) and (max-width:1199px) {
    .container{
       
        padding: 5rem 2.5rem ;
        
        }
    .subContainer{
        width: 95%;
    }
    
}
@media screen  and (max-width:768px ) {
    .container{
       
        padding:   0px ;
        
        }
    .subContainer{
        width: 100%;
        padding: 0px 20px 10px 20px;
        flex-direction: column;
        align-items: center;
        
        
    }
    
}


