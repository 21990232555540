.horizontalCard{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 40px;
    /* border: 1px solid black;  */
}
.stratagyContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 10px;


}
.stratagy{
    padding: 10px;
    font-size: 16px;
    font-weight: 400;
    text-align: start;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
}
.stratagyTitle{
    padding: 10px;
    font-size: 25px;
    font-weight: 700;
    line-height: 25px;
    text-align: start;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
}
.stratagyDescription{
    padding: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px; 
    text-align: start;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
}

@media (min-width: 992px) and (max-width: 1200px) {}
@media (min-width:768px) and (max-width: 992px) {}
@media (max-width: 768px) {}