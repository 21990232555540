.loader {
    width: 60vw;
    height: 500px;
    margin-bottom: 10px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 5.5s infinite;
  }
  
  @keyframes loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
.coverImg{
    width: 60vw;
    margin-bottom: 10px;
    filter: grayscale(200%);
    transition: filter 300ms;
    
}
.coverImg:is(:hover, :focus){
    filter: grayscale(0%);
}

.image{
    width: 50vw;
    margin-bottom: 10px;
    margin-top: 10px;
    filter: grayscale(200%);
    transition: filter 300ms;
}
.image:is(:hover, :focus){
    filter: grayscale(0%);
}

@media screen and (min-width:993px) and (max-width:1199px) {
  .loader{
    height: 371px;
  }
  
}
@media screen and (min-width:769px) and (max-width:992px) {
  .loader{
    height: 275px;
  }
  
}

@media screen and (max-width:768px) {
    .coverImg{
        width: 80vw;
      
}
.loader {
    width: 80vw;
    height: 175px;
}
}

