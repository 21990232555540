
.sklbt-in-nav{
    outline: none !important;
    font-family: "Lato" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1.188rem !important;
    line-height: 1.2rem !important;
    letter-spacing: 0.5px !important;
    height: 2.4rem;
    
} 

.selected-nav-el{
    font-weight: 700 !important;
    /* border: solid black; */
    /* border-width: 0px 0px 2px 0px; */
}

.cursorPointer{
    cursor: pointer;
}

@media (max-width: 768px) {
    .sklbt-in-nav{
        font-size:.8rem !important;
        /* text-align: center !important; */
    }
}

