
.carousel-wrapper {
    width: 80%;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: auto;
    /* border: 1px solid red; */
}

  .carousel-image-wrapper{
    width: 80%;
    /* height: 50%; */
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  
 
  .carousel-image{
    width: 60%;
    /* min-height: 300px; */
    max-height: 400px;
    padding: 10px;
    margin: 0;
    filter: grayscale(200%);
    transition: filter 300ms;

  }
  .carousel-image:is(:hover, :focus){
    filter: grayscale(0%);
}


  @media screen and (min-width: 993px) and (max-width:1199px) {
    .carousel-wrapper {
      width: 100%;
      position: relative;
      overflow: hidden;
  }
  .carousel-image-wrapper{
    width: 80%;
  }
  .carousel-image{
    max-width: 100%;
    transition: transform 0.2s ease-in-out;
    max-height: 400px;
  }
  
}

@media screen and (min-width: 769px) and (max-width:992px) {
  .carousel-wrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
}
.carousel-image-wrapper{
  width: 70%;
}
.carousel-image{
  max-width: 100%;
  transition: transform 0.2s ease-in-out;
}
}
@media screen and  (max-width:768px) {
  .carousel-wrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
}
.carousel-image-wrapper{
  width: 100%;
  padding: 0;
  max-height: 190px;
}
.carousel-image{
  width: 100%;
  max-height: 190px;
  padding: 0;
}
}