.title{
    font-size: 45px;
    font-weight: 400;
    font-family: 'Lato' sans-serif;
    color: #000000;
    opacity: 0.9;
    word-wrap: break-word;
    text-align: start;
    margin-bottom: 10px;
}

.sub-heading{
    font-size: 24px;
    line-height: normal;
    font-weight: 700;
    word-wrap: break-word;
    text-align: start;
    margin-bottom: 10px;
}

.sub-context{
    font-size: 18px;
    font-weight: 700;
    word-wrap: break-word;
    text-align: start;
    margin-bottom: 10px;
}

@media screen and (min-width: 993px) and (max-width:1199px) {
    .title{
        font-size: 40px;
    }
}

@media screen and (min-width: 769px) and (max-width:992px) {
    .title{
        font-size: 35px;
    }
}

@media screen and (max-width: 768px) {
    .title {
        font-size: 24px;
        line-height: 140%;
        margin-bottom: 0px;
      }
      .sub-heading{
        font-size: 18px;
        line-height: 140%;
        margin-bottom: 0px;
      }
      .sub-context{
        font-size: 16px;
        margin-bottom: 0px;
        line-height: 140%;
    }
}