
.content{
    font-size: 16px;
    font-weight: 400;
    font-family: 'Lato' sans-serif;
    color: #303030;
    line-height: 140%;
    width: 70vw;
    text-align: start;
    white-space: normal;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;

    
}


/* .content:hover {
    animation: shakeContent 5s;
    animation-iteration-count: 2;
    animation-timing-function: ease-in-out;
    border: 1px solid red;
  }
  
  
  @keyframes shakeContent {
    0%, 100% {
      transform: translateX(0) translateY(0);
    }
    25% {
      transform: translateX(2px) translateY(2px);
    }
    50% {
      transform: translateX(-2px) translateY(-2px);
    }
    75% {
      transform: translateX(2px) translateY(2px);
    }
  } */

@media screen and (max-width: 992px) { 
    .content
     {
      width: 100%;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 0px;
    }
}
