.ourWorkContainer{
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
    width: 100%;
}

.ourWorkSubContainer{

    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
      /* border: 1px solid pink; */
    }

.cardsContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Added gap for spacing between cards */
    /* border: 1px solid green; */
    
  }


@media (min-width: 992px) and (max-width: 1200px) {
    .ourWorkContainer{
        justify-content: center;
        width: 100%;
        /* border: 1px solid pink; */
    }
    .ourWorkSubContainer{
        width: 95%;
    }
}

@media (min-width:769px) and (max-width: 991px) {
    .ourWorkSubContainer{
        width: 90%;
        /* border: 1px solid yellow; */
    }
    
      .scrollableContainer{
        overflow-x: auto;
        white-space: nowrap;
        display: inline-flex;
        width: 100%;
    }
    .cardsContainer {
        display: inline-flex;
        flex: 0 0 auto; /* Allow cards to shrink when necessary */
        margin-right: 10px; 
        flex-wrap: nowrap;
        /* width: 100%; */
        /* flex-wrap: nowrap;
        overflow: hidden; */
      }
}

@media (max-width: 768px) {
    .ourWorkSubContainer{
        width: 95%;
        /* border: 1px solid yellow; */
    }
    .scrollableContainer{
        overflow-x: auto;
        white-space: nowrap;
        display: inline-flex;
        width: 100%;
   }
   .cardsContainer {
        display: inline-flex; 
        flex: 0 0 auto; /* Allow cards to shrink when necessary */
        margin-right: 10px; 
       /* width: auto; */
       flex-wrap: nowrap;
       /* overflow: hidden; */ 
     }
}