.cardTitle{
    font-size: 18px;
    font-weight: 600;
    word-wrap: break-word;
    white-space: normal; 
    overflow-wrap: break-word
    
}


@media screen and (max-width:768px) {
    .cardTitle{
        font-size: 16px;
    }
    
}