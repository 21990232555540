.whatWeThinkContainer{
    background-color: rgb(239, 239, 239);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;
    width: 100%;
    /* border: 1px solid red;    */
}

.whatWeThinkSubContainer{
    width: 75%;
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: center; */
    /* border: 1px solid blue */
}

.horizontalCardContainer{
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    gap: 10px;
    /* border: 1px solid pink */

}

@media (min-width: 992px) and (max-width: 1200px) {
    .whatWeThinkSubContainer{
        width: 90%;
    }
}
@media (min-width:768px) and (max-width: 992px) {
    .whatWeThinkSubContainer{
        width: 90%;
    }
}
@media (max-width: 768px) {

    .whatWeThinkContainer{
        display: none;
    }

    .whatWeThinkSubContainer{
        width: 95%;
    }
}