

.container{
    display: flex;
    flex-direction: column;
    gap: 35px;
    /* padding-bottom: 40px; */
}




.title{
    color: #000;
    font-size: 33px;
    /* line-height: 140%; */
    font-weight: 600;
    text-align: left;
    margin: 0px;
    pad: 0px;
    line-height: 20px;
}
.subTitle{
font-size: 20px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
margin: 0px;

}
.inputStyle{
    font-weight: 400;
    font-size: 16px;
    line-height: 19.6px;
    color: #000;
    opacity: 0.7;
    background-color: #dddddd;
    border: 0px;
    border-radius: 4px;
    width: 100%;
  height: 40px;
  padding: 4px 12px;
    

}

.messageStyle{
  height: 120px;
  resize: none;
  overflow-y: auto;
}
.nameContainer{
  width: 100%;
  text-align: start;
  height: auto;
}

.submitButton{
  background: #171717;
  color: #fff;
  padding:  10px;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  border: 0px;
  border-radius: 4px;
  

}


@media screen and (min-width:769px) and (max-width:992px) {
  .mobileContainer{
    width: 100%;
  }
  
}

@media screen and (max-width:768px) {
    .container{
        gap: 20px;
        padding-bottom: 20px;
    }
  .mobileContainer{
    width: 100%;
  }
  .title{
    text-align: center;
}
.subTitle{
text-align: center;


}
  
}

/* border-0 border-bottom border-white */

/* border-0 border-bottom border-white */