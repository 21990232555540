  

  .heading{
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
    margin-bottom: 20px;
}


@media (min-width: 992px) and (max-width: 1200px) {
    .heading{
        font-size: 30px;
        line-height: 30px;
    }
}

@media (min-width:768px) and (max-width: 992px) {
    .heading{
        font-size: 30px;
        line-height: 30px;
    }
}

@media (max-width: 768px) {
    .heading{
        width: 100%;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        margin-bottom: 10px;
    }
}