
.requirement-container {
    padding: 0px 10px;
    display: flex;
    align-items: flex-start; 
    justify-content: flex-start;
    
  }

  

.circularIcon{
  width: 15px;
  height: 15px;
  margin-top: 7px;
}

.requirement-content{
    font-size: 16px;
    /* line-height: 30px; */
    font-weight: 400;
    font-family: 'Lato' sans-serif;
    color: #000000;
    opacity: 0.8;
    /* text-align: center; */
    word-wrap: break-word;
    width: 100%;
    text-align: start;
    margin: 2px 0;

}

  /* for tablets */
  @media screen and (max-width: 992px) {
    
  
    .requirement-content {
      width: 100%;
      font-size: 14px;
    }
    .right-arrow {
      width: 14px; 
      height: 14px;
    }
  } 
  
   /* for mobile */
  /* @media screen and (max-width: 768px) {
    
  
    .requirement-content {
      font-size: 14px;
      width: 100%;
    }
  } */