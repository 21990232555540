  

  .description{
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: start;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
}


@media (min-width: 992px) and (max-width: 1200px) {
    .description{
        line-height: 26px;
    }
}

@media (min-width:768px) and (max-width: 992px) {
    .description{
        font-size: 15px;
        line-height: 26px;
    }
}

@media (max-width: 768px) {
    .description{
        font-size: 15px;
        line-height: 24px;
    }
}