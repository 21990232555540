.scrollContainer{
    display: none;
}



@media (max-width: 768px) {
.scrollContainer{
    display: flex;
    flex-direction: column;
    width: 90%;
  }
  .designThinkingDescriptionContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
  .scrollableImageContainer{
    overflow-x: auto;
     white-space: nowrap;
     display: flex;
     flex-direction: column;
     width: 100%;
  }
  .scrollaableImgSize{
    max-width: 280px;
    max-height: 180px;
    
  }
  
  .scrollMobileImage{
    width: 100%;
    max-width: 280px;
    height: 100%;
    margin-right: 10px;
  }

  .scrollableImage{
    display: inline-flex;        
     flex: 0 0 auto; /* Allow cards to shrink when necessary */
     margin-right: 10px; 
    width: auto;

  }
  /* .scrollMobileImage{
    width: 280px;
    height: 250px;
  } */
}