.container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color:#FFFFFF;
    width: 40%;
    border-radius: 10px;



}
.imageContainer{
    width: 80%;
    /* border: 1px solid red; */
    text-align: end;
}
.contactUsImage{
    width: 80%;
    /* border: 1px solid red; */
}
.subContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    /* border: 1px solid red; */
}

.contactUsTitle{
    color: #000;
    font-size: 2.063rem;
    font-weight: 700;
}

.subHeading{
    color: #000;
    font-size: 24px;
    font-weight: 500;
    text-align: start;
}

.subHeadingPosition{
    color: #000;
    font-size: 16px;
    font-weight: 500;
    /* opacity: 0.8; */
    text-align: start;
    
}

.contactNo{
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    /* margin: 0px; */
}
.cursor{
    cursor: pointer;
}
@media screen and (min-width:992px) and (max-width:1199px) {
    
    .subHeading{
        font-size: 1.4rem;
        font-weight: 600;
    } 
    .subHeadingPosition{
        font-size: 1rem;    
    }
}   

@media screen and (min-width:769px)and (max-width:991px)  {
    .container{

        flex-direction: column;
        align-items: center;

        width: 40%;

    } 
    .imageContainer{
        width: 100%;
    }
    .subHeading{
        font-size: 1.2rem;
        font-weight: 600;
    }
    .subHeadingPosition{
        font-size: .75rem;    
    }  
}
@media screen and (max-width:768px)  {
    .container{
        /* display: none; */
        flex-direction: column;
        align-items: center;
        /* padding-top: 3.5rem; */
        width: 90%;
        /* padding: 0px; */
        /* border: 1px solid red; */
    } 
    .imageContainer{
       display: none;
    }
    .subContainer{
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        width: 100%;
    }
    .subHeading{
        font-size: 1.5rem;
        font-weight: 500;
        margin: 0px;
    }
    .subHeadingPosition{
        font-size: 1rem;    
    }  
    
}
/* @media screen and (min-width:768px) and (max-width:991px) {
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 3.5rem;
    }  
    .contactUsTitle{
        text-align: start;
        

    } 
} */

/* @media screen and (max-width:768px){
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 3.5rem;
    }  
    .contactUsTitle{
        text-align: start;
        

    } 
} */