
  .logos{
    width: 70vw;
    overflow: hidden;
    padding: 60px 0;
    background: #fff;
    white-space:nowrap ;
    position: relative;
  }

  

  

  .logosSlideImg {
    height: 50px;
    margin-right: 20px;
    margin-left: 20px;
  }

  @media screen  and (max-width:768px) {
    .logos{
      width: 80vw;
    }
    
  }