
.descriptionContainer{
    background-color: rgb(239, 239, 239);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    
    /* border: 1px solid red; */
}
.descriptionSubContainer{
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: center;
    gap: 5px;
    /* padding: 20px 30px; */
    /* border: 1px solid red; */
}
.customCardContainer{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    /* border: 1px solid green; */

}
.cardsContainer{
    width: 100%;
    display: flex;
    flex-wrap: wrap; /* Changed to wrap here */
    gap: 20px; /* Added gap for spacing between cards */
    /* border: 1px solid pink; */
}

@media (min-width: 992px) and (max-width: 1200px) {
    
    .descriptionContainer{
        justify-content: center;
        width: 100%;
        /* border: 1px solid pink; */
            
    }
    
    .descriptionSubContainer{
        width: 95%;
        padding: 5px 30px;
    }
    .cardsContainer{
        flex-direction: row;
        justify-content: center;
    }
}
@media (min-width:768px) and (max-width: 992px) {
    .descriptionContainer{
        justify-content: center;
            
    }
    
    .descriptionSubContainer{
        width: 90%;
        /* padding: 5px 30px; */
    }
    .cardsContainer{
        flex-direction: row;
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .descriptionContainer{
        justify-content: center;
        padding: 0px;
        padding-top: 10px;
            
    }
    
    .descriptionSubContainer{
        width: 90%;
    }
    .customCardContainer{
        overflow-x: auto;
         white-space: nowrap;
         display: inline-flex;
         /* flex-direction: row; */
         /* justify-content: flex-start; */
         /* width: 60%; */
    }
    .cardsContainer{
        display: inline-flex;        
         /* flex: 0 0 auto; */
         margin-right: 10px; 
         padding: 10px;
        width: auto;
        justify-content: flex-start;
        /* flex-wrap: nowrap; */
        /* overflow: hidden; */
    }
}