.ourClientBackground{
    background-color: #000000E8;
}
.titleText{
    font-size: 2.0625rem;
    font-weight: 700;
}


.clientContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}


.logoContainer {
    position: relative;
    width: 225px;
    height: 140px;
    perspective: 1000px;
    background-color: black;
    padding: 0px 10px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .logoClient {
    transform-style: preserve-3d;
    transition: transform 3s;
    height: 35px;
  }
  
  .logoClient:hover {
    transform: rotateY(360deg);
    
  }
 
.logosAnimation{
  display: none;
   width: 95vw;
  overflow: hidden;
  padding: 60px 0;
 white-space:nowrap ;
  position: relative;
}


.logosSlideImg {
  height: 40px;
  margin-right: 20px;
    margin-left: 20px;
}

@media (max-width: 991px) {
  .clientContainer{
    display: none;
    
  }
  .logosAnimation{
    display: inline-flex;
  }
 
  
}
  