.whatWeThinkContainer{
    background-color: rgb(239, 239, 239);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;
    /* border: 1px solid red;   */
    width: 100%; 
}

.whatWeThinkSubContainer{
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: center; */
    /* border: 1px solid blue */
}

.imageConatiner{
    display: flex ;
    flex-direction: row;
    width: 100%;
    /* border: 1px solid green; */

}

.thinkLeftContainer{
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    
}

.img1Wrapper{
    width: 100%;
    /* max-width: 615px; */
    padding: 5px;
    /* border: 1px solid red; */
}

.img1Wrapper > img {
    width: 100%; 
    /* max-width: 615px; */
    height: auto; 
  }

.thinkRightContainer{
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.img2Wrapper{
    width: 100%;
    /* max-width: 435px; */
    padding: 5px;
    border: 1px solid red;
}
.img2Wrapper > img {
    width: 100%; 
    /* max-width: 615px; */
    height: auto; 
  }
  .img2Wrapper:first-child {
    flex-grow: 60; /* 60% height */
  }
  
  .img2Wrapper:last-child {
    flex-grow: 40; /* 40% height */
    /* border: 1px solid black; */
  }


  @media (min-width: 992px) and (max-width: 1200px) {
    .whatWeThinkContainer{
        width: 100%;
    }
    .whatWeThinkSubContainer{
        width: 95%;
    }    
  }
  @media (min-width:768px) and (max-width: 992px) {
    .whatWeThinkContainer{
        width: 100%;
    }
    .whatWeThinkSubContainer{
        width: 90%;
        /* border: 1px solid red; */
    }
  }
  @media (max-width: 768px) {
    .whatWeThinkContainer{
        display: none;
      }
  }