.customCardContainer{
    /* width: 75%; */
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;

}
.cardsContainer {
    /* width: 100%; */
    display: flex;
    flex-wrap: wrap; /* Changed to wrap here */
    gap: 10px; /* Added gap for spacing between cards */
  }
  .customCard {
    max-width: 320px;
    width: 100%; 
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding: 10px 0px; 
    /* padding-bottom: 30px; */
    /* background-color: #fff; */
    border-radius: 25px;
    box-shadow: 0px 4px 4px 0px #0000001A;

  }

.cardTitle{
  /* text-align: start; */
    color: #fff;
    opacity: 0.9;
    font-size: 22px;
    padding: 10px;
    font-weight: 700;
    line-height: 25px;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
  }

.cardDescription{
  color: #fff;
    opacity: 0.9;
    font-size: 15px;
    padding: 10px;
    font-weight: 400;
    width: 100%;
    line-height:28px;
    text-align: center;
    /* word-wrap: break-word; */
    white-space: normal; /* Ensure normal white-space handling */
    /* overflow-wrap: break-word; */
    display: -webkit-box;
    /* -webkit-line-clamp: 4; */
    -webkit-box-orient: vertical;
    min-height: 8rem;
    line-height:20px;
  }


   @media (min-width: 992px) and (max-width: 1200px) {
    .customCardContainer{
        /* width: 90%; */
        
    }
    .cardsContainer{
        justify-content: center;
        
        gap: 20px;
    }
  }
  @media (min-width:768px) and (max-width: 992px) {
    .customCardContainer{
        /* width: 90%; */
        
    }
    .cardsContainer{
        justify-content: center;
        
        gap: 10px;
    }
    .customCard{
        max-width: 300px;
    }
    .cardTitle{
        font-size: 18px;
      }
  } 
  @media (max-width: 768px) {
    .customCardContainer {
        overflow-x: auto;
        white-space: nowrap;
        display: flex;
        justify-content: flex-start; 

        padding: 10px 10px; 
      }
    
       .cardsContainer {
            display: inline-flex; 
            flex: 0 0 auto; 
            gap: 0;
           width: auto;
         }
    
         .customCard{
            max-width: 280px;
         }
         .cardTitle{
          font-size: 16px;
         }
         .cardDescription{
          font-size: 14px;
         }
  }