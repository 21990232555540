
.mainContainer{
  margin-top: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: 50px; */

}
.innerNav{
  display: flex;
  width: 75%;
  padding: 0px 20px;
}
.introContainer{
  display: flex;
  flex-direction: row;
  padding: 10px 30px;
  justify-content: space-between;
  align-items: center;
  width: 75%;

  
}


.leftIntro {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  padding-top: 40px;

}

/* .leftIntro > svg {
  width: 70px;
  height: 70px
} */

.skalebotTitle{
  font-size: 59px;
  font-weight: 700;
  line-height: 60.5px;
  font-family: 'Lato' sans-serif;
  text-align: start;
  margin: 0;
  /* word-wrap: break-word; */
}
.skalebotSubTitle{
  font-size: 59px;
  font-weight: 500;
  line-height: 40.5px;
  font-family: 'Lato' sans-serif;
  text-align: start;
  margin: 0;
  
}
.skalebotContent{
  font-size: 18px;
  font-weight: 400;
  text-align: start;
  line-height: 32.4px;

}
.buttonContainer{
  display: flex;
  flex-direction: row;
  gap: 10px;
  /* margin-top: 10px; */

}
.button{
  background: none;
  border:1px solid #000;
  border-radius: 6px;
  padding: 5px 30px;
  font-size: 14px;
  font-weight: 500;
}

.rightIntro{
  width: 50%;
  /* border: 1px solid red; */
}

.rightIntro >img {
  width: 100%;
}

.rightIntroMobile{
  display: none;
}

.benifitsContainer{
  width: 75%;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}


.benifitsHeading{
font-size: 36px;
font-weight: 700;
line-height: 48px;
letter-spacing: 0px;
text-align: center;

}

.cardsContainer{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  
}
.cardWraper{
  padding: 5px;
  
}

.customCard{
  gap: 15px;
  padding: 30px 10px 20px 10px;
  width: 340px;
  /* height: 240px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FAFAFA;
}
.cardTitle{
  font-size: 18px;
  font-weight: 700;
  
}
.cardDescription{
  font-size: 16px;
  font-weight: 300;
  width: 100%;
  word-wrap: break-word;
  white-space: normal; 
  overflow-wrap: break-word;
  overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* Number of lines to show */
    -webkit-box-orient: vertical;
    /* max-height: 1.5rem; */
    min-height: 6.5rem;
  line-height: 1.625rem; /* Adjust based on your line height */
}

.videoContainer{
  width: 75%;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  max-width: 100%; 
margin: 0 auto; 
}
.videoTitle{
  font-size: 40px;
  font-weight: 700;
}
.videoText{
  font-size: 29px;
  font-weight: 400;
}

.iframe{
  margin-top: 15px;
  max-width: 100%; 
}

.marketingContainer{
  width: 75%;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.anchorTag{
  text-decoration: none;
  color: #000;
}
.marketingCard{
  padding: 10px 3px;
  width: 330px;
  /* height: 240px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
  
}

.marketingTitle{
  font-size: 36px;
  font-weight: 700;
  word-wrap: break-word;
  white-space: normal; 
  overflow-wrap: break-word;
  margin: 0;
  
}
.marketingImageContainer{
  width: 100%;
  
}
.marketingImage{
  width: 100%;
  
}
.publishBy{
  font-size: 14px;
  font-weight: 600;
  color: #EAEAEA;
  word-wrap: break-word;
  white-space: normal; /* Ensure normal white-space handling */
  overflow-wrap: break-word;
  margin: 0;
  padding: 5px 0;
 
}
.publishTitle{
  font-size: 24px;
  font-weight: 600;
  color: #000;
  word-wrap: break-word; 
  white-space: normal; /* Ensure normal white-space handling */
  overflow-wrap: break-word;
  text-align: start; 
  margin: 0;
  padding: 5px 0;
  
}
.publishDescription{
  font-size: 16px;
  font-weight: 400;
  text-align: start;
  word-wrap: break-word;
  white-space: normal; /* Ensure normal white-space handling */
  overflow-wrap: break-word;
  margin: 0;
  padding: 5px 0;
  
}
.badgesContainer{
  display: flex ;
  flex-direction: row;
  padding: 5px 0;

}
.badges{
  background-color: #d4d3d5;
  color: #000;
  padding: 2px 10px;
  font-size: 14px;
  font-weight: 500;
}
.whatsappContainer{
margin-top: 10px;
padding-top: 10px;
padding-bottom: 10px;
padding: 10px 25px;
  display: flex;
  flex-direction: row;
  /* padding: 10px; */
  justify-content: space-around;
  align-items: center;
  width: 75%;
 
}
.qrDetails{
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  align-items: flex-start;
  /* width: 75%; */
  
}
.qrTitle{
  font-size: 70px;
  font-weight: 400;
  word-wrap: break-word;
  text-align: start;
  white-space: normal; /* Ensure normal white-space handling */
  overflow-wrap: break-word;
  /* width: 70%; */
}
.qrSubTitle{
  font-size: 28px;
  font-weight: 700;
  word-wrap: break-word;
  white-space: normal; /* Ensure normal white-space handling */
  overflow-wrap: break-word;
  text-align: start;
}
.qrmobileTitle{
  font-size: 14px;
  font-weight: 400;
  color: #7B6DF8;
}
.storeContainer{
  display: flex;
  flex-direction: row;
  /* padding: 10px; */
  /* justify-content: space-around; */
  align-items: center;
}
.contactUsContainer{
display: flex;
flex-direction: column;
align-items: center;
width: 75%;
padding-top: 10px;
padding-bottom: 10px;
/* margin-top: 10px; */
margin-top: 10px;
}
.contactUsTitle{
  font-size: 40px;
  font-weight: 700;
  word-wrap: break-word;
}

.formContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 5rem;
  padding: 30px;
  gap: 20px;
  
}

.inputDiv{
  width: 328px;
  height: 40px;
}

.inputStyle{
  width: 100%;
  padding: 10px;
  font-weight: 400;
  font-size: 15px;
  line-height: 19.6px;
  color: #000000;
  opacity: 0.7;
  background-color: black;
  border: 1px solid #000;
  /* outline-color: black; */
  
}

.submitButton{
  font-weight: 500;
  font-size: 14px;
  color: #FFF;
  background-color: #000;
  border: 1px solid #000;
  border-radius: 10px;
  margin-left: 20px;
  padding: 5px 20px ;

}


@media (max-width: 768px) {
.introContainer,.innerNav,.leftIntro, .benifitsContainer, .videoContainer, .marketingContainer, .whatsappContainer, .contactUsContainer {
  width: 90%;
  
}
.mainContainer{
  /* gap: 30px; */

}
.leftIntro {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0px;

}
.innerNav{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 20px; */
}

.introContainer{
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
}



.rightIntro {
  display: none;
  width: 100%;
  padding: 0; 
  margin-top: 20px; 
}
.rightIntro > img {
  width: 100%; 
  height: auto; 
}
.rightIntroMobile{
  display: flex;
  width: 100%;
  padding: 0; 
  margin-top: 20px; 
}

.rightIntroMobile >img {
  width: 100%; 
  height: auto; 
}

.skalebotTitle {
  font-size: 40px;
  line-height: 30px;
}

.skalebotSubTitle {
  font-size: 30px;
  text-align: center;
}
.buttonContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin-top: 10px; */

}


.customCard, .marketingCard {
  width: 280px;
}

.scrollableContainer {
  
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  
}


.cardsContainer {
  display: inline-flex;
  flex: 0 0 auto; /* Allow cards to shrink when necessary */
  margin-right: 10px; 
  width: auto;
}

.whatsappContainer{
  flex-direction: column-reverse;

}
.qrDetails{
  align-items: center;

}
.qrTitle{
  font-size: 30px;
  text-align: center;
}
.qrSubTitle{
  font-size: 24px;
  text-align: center;
}
.formContainer{
  flex-direction: column;
  gap: 20px;
  margin-bottom: 5px;
}
.inputDiv{
  height: auto;
}
.submitButton{
  width: 50%;
  border-radius: 50px;
  margin-left: 0;
}
.buttonContainer{
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;

}
.button{
  padding: 5px 20px;
  font-size: 10px;
  margin-top: 10px;
}
.cardTitle{
  font-size: 16px;
  font-weight: 700;
  
}
.cardDescription{
  font-size: 14px;
}
.benifitsHeading{
font-size: 30px;
line-height: 140%;
}
.videoTitle{
  font-size: 30px;
}
.videoText{
  font-size: 18px;
  font-weight: 400;
}
.marketingTitle{
  font-size: 30px; 
}

}

@media (min-width: 769px) and (max-width: 991px) {
  .introContainer,.innerNav, .benifitsContainer, .videoContainer, .marketingContainer, .whatsappContainer, .contactUsContainer {
  width: 90%;
  
}
.mainContainer{
  gap: 10px;

}

.innerNav{
  /* width:50%; */
  width:80%;
  align-items: center;
  justify-content: center;
}
/* .introContainer{
  align-items: flex-start;
  flex-direction: row;
} */

.introContainer{
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.leftIntro{
  gap: 5px;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
  /* width: 100%; */

}
/* .rightIntro{
  width: 60%;
    padding: 60px 0px 10px 0px;
    display: flex;
    align-items: flex-end;
} */
.rightIntro {
  width: 100%;
  padding: 0; 
  margin-top: 20px; 
}
.rightIntro > img {
  width: 100%; 
  height: auto; 
}

.skalebotTitle {
  font-size: 40px;
}

.skalebotSubTitle {
  font-size: 30px;
}

.customCard, .marketingCard {
  width: 280px;
}

.scrollableContainer {
  
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  
}


.cardsContainer {
  display: inline-flex;
  flex: 0 0 auto; /* Allow cards to shrink when necessary */
  margin-right: 10px; 
  width: auto;
}

.whatsappContainer{
  flex-direction: column-reverse;

}
.qrDetails{
  align-items: center;

}
.qrTitle{
  font-size: 30px;
  text-align: center;
}
.qrSubTitle{
  font-size: 24px;
  text-align: center;
}
.formContainer{
  flex-direction: column;
  /* gap: 40px; */
  margin-bottom: 5px;
}
.submitButton{
  width: 40%;
  border-radius: 50px;
  margin-left: 0;
}
.button{
  padding: 5px 10px;
  font-size: 10px;
  margin-top: 10px;
}
.cardTitle{
  font-size: 16px;
  font-weight: 700;
  
}
.cardDescription{
  font-size: 14px;
}
.benifitsHeading{
font-size: 30px;
line-height: 140%;
}
.videoTitle{
  font-size: 30px;
}
.videoText{
  font-size: 18px;
  font-weight: 400;
}
.marketingTitle{
  font-size: 30px; 
}



}

@media (min-width: 992px) and (max-width: 1199px) {
.innerNav{
   width: 75%;
}
  .introContainer{
      flex-direction: row;
      /* justify-content: space-around; */
      
  }
  .rightIntro{
    width: 60%;
      padding: 70px 0px 10px 0px;
      display: flex;
      align-items: flex-end;
      /* border: 1px solid red; */
  }
  .leftIntro{
    gap: 5px;
  }

.skalebotTitle {
  font-size: 40px;
}

.skalebotSubTitle {
  font-size: 30px;
}
.button{
  padding: 5px 10px;
  font-size: 10px;
}
}

@media (min-width: 1200px) {
.skalebotTitle {
  font-size: 55px;
}

.skalebotSubTitle {
  font-size: 40px;
}

}
