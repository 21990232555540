.container{
    height: 100lvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.error{
        font-size: 3rem;
        margin: 0px;
        line-height: normal;
}

@media screen and (max-width:768px) {

    .container{
    /* height: 90vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
}